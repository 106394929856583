import { Asset, Metadata } from '@oresundsbron/api';
import { Link } from '@oresundsbron/api/src/contentful/Links';
import { filter, concat } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/function';

const Keys = [
  'seo-title',
  'seo-description',
  'robots',
  'og:image',
  'og:image:alt',
  'og:locale',
  'og:type',
  'og:url',
  'canonical',
] as const;

export type MetaKeys = typeof Keys[number];

export type MetadataItem = { name: MetaKeys; content: string };

export const noIndexMetaTag: MetadataItem = {
  name: 'robots',
  content: 'noindex',
};

export const toMetadata = (metadata: Metadata[] = []): MetadataItem[] => {
  return metadata
    ?.filter((item) => Keys.includes(item.name?.codeId as MetaKeys))
    .map(
      ({ name, content }) =>
        ({
          name: name.codeId,
          content: content,
        } as MetadataItem)
    );
};
export const mergeMetadata = (base: MetadataItem[], items: MetadataItem[]) =>
  pipe(
    base,
    filter((x) => !items.some((i) => i.name === x.name)),
    concat(items)
  );

export const addCanonicalHref = (
  metaDataArray: MetadataItem[],
  metadata?: Metadata[]
) => {
  const canonicalHref = metadata?.filter(
    (item) => item?.name?.codeId === 'seo-canonicalHref'
  )?.[0];
  if (canonicalHref) {
    metaDataArray = [
      ...metaDataArray,
      { name: 'canonical', content: canonicalHref.content || '' },
    ];
  }
  return metaDataArray;
};

interface CreateMetaData {
  title?: string;
  preamble?: string;
  link?: Link;
  mainImage?: Asset;
  contentType: 'article' | 'website';
  removeFromSitemap?: boolean;
  metadata?: Metadata[] | undefined;
}

export const createMetaDataItems = (data: CreateMetaData) => {
  let newMetadataItems: MetadataItem[] = [
    { name: 'seo-title', content: data?.title || '' },
    { name: 'seo-description', content: data?.preamble || '' },
    { name: 'og:type', content: 'article' },
  ];

  if (data.mainImage) {
    newMetadataItems = [
      ...newMetadataItems,
      { name: 'og:image', content: data.mainImage?.url || '' },
      {
        name: 'og:image:alt',
        content: data.mainImage?.description || '',
      },
    ];
  }

  if (data.link) {
    newMetadataItems = [
      ...newMetadataItems,
      { name: 'og:url', content: data.link.path },
    ];
  }

  if (data?.removeFromSitemap) {
    newMetadataItems = [...newMetadataItems, noIndexMetaTag];
  }

  newMetadataItems = addCanonicalHref(newMetadataItems, data.metadata);

  return newMetadataItems;
};
